// Here you can add other styles
.float-right{
    float:right;
}

.pr-15{
    padding-right: 15px;
}

table {
    table-layout: fixed;
    width: 100%;
    white-space: pre-wrap;
}

th,td {
    white-space: prewrap;
    padding:5px!important;
}

td .btn{
    text-align: center!important;
}

.btn-link{
    padding:0px!important;
}

.float-right {
    float:right;
}

.float-left {
    float:left;
}

.card-header{
    font-weight: bold;
}

.width-100{
    width:100%;
}

.width-90{
    width:90%!important;
}

.width-80{
    width:80%!important;
}

.width-70{
    width:70%!important;
}

.width-60{
    width:60%!important;
}

.width-50{
    width:50%!important;
}

.width-40{
    width:40%!important;
}

.width-30{
    width:30%!important;
}

.width-20{
    width:20%!important;
}

.width-10{
    width:10%!important;
}